export const UserActionTypes = {
  ACTION_LOGIN: 'ACTION_LOGIN',
  ACTION_RESET_TOKEN: 'ACTION_RESET_TOKEN',
  ACTION_RESET_ID: 'ACTION_RESET_ID',
  ACTION_RESET_LOGIN_NAME: 'ACTION_RESET_LOGIN_NAME',
  ACTION_RESET_REAL_NAME: 'ACTION_RESET_REAL_NAME',
  ACTION_RESET_SITE_ID: 'ACTION_RESET_SITE_ID',
  ACTION_RESET_SITE_CODE: 'ACTION_RESET_SITE_CODE',
  ACTION_RESET_AFFILIATE_LEVEL: 'ACTION_RESET_AFFILIATE_LEVEL',
  ACTION_GET_USER_INFO: 'ACTION_GET_USER_INFO',
  ACTION_LOGOUT: 'ACTION_LOGOUT',
  ACTION_UPDATE_LOGIN: 'ACTION_UPDATE_LOGIN',
  ACTION_UPDATE_REAL_NAME: 'ACTION_UPDATE_REAL_NAME',
  ACTION_REGISTER: 'ACTION_REGISTER'
}
