<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <div>
          <el-date-picker
            v-model="request.recordTime"
            format="DD/MM/YYYY"
            value-format="YYYY-MM-DD"
            type="daterange"
            range-separator=":"
            :start-placeholder="t('fields.startDate')"
            :end-placeholder="t('fields.endDate')"
            style="width: 300px; margin-left: 10px"
            :shortcuts="shortcuts"
            :editable="false"
            :clearable="false"
          />

          <el-button
            style="margin-left: 20px"
            icon="el-icon-search"
            type="success"
            @click="loadRecord()"
          >
            {{ t('fields.search') }}
          </el-button>
          <el-button @click="resetQuery()">
            {{ t('fields.reset') }}
          </el-button>
        </div>
      </div>
    </div>

    <el-card class="box-card" shadow="never" style="margin-top: 20px">
      <el-table
        size="small"
        :resizable="true"
        :data="page.records"
        v-loading="page.loading"
        row-key="id"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :empty-text="t('fields.noData')"
        :summary-method="getSummaries"
        show-summary
        highlight-current-row
      >
        <el-table-column
          prop="recordTime"
          :label="t('fields.recordTime')"
          align="center"
        >
          <template #default="scope">
            <span
              v-formatter="{
                data: scope.row.recordTime,
                formatter: 'YYYY-MM-DD',
                type: 'date',
              }"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="registerCount"
          :label="t('fields.registerCount')"
          align="center"
        >
          <template #default="scope">
            <el-link
              v-if="scope.row.registerCount !== 0"
              type="primary"
              @click="showDialog(scope.row.recordTime)"
            >
              {{ scope.row.registerCount }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="ftdCount"
          :label="t('fields.ftdCount')"
          align="center"
        />
        <el-table-column
          prop="ftdAmount"
          :label="t('fields.ftdAmount')"
          align="center"
        >
          <template #default="scope">
            $
            <span v-formatter="{data: scope.row.ftdAmount, type: 'money'}" />
          </template>
        </el-table-column>
        <el-table-column
          prop="eventClickCount"
          :label="t('fields.eventClickCount')"
          align="center"
        />
      </el-table>
      <el-pagination
        :total="page.total"
        :page-sizes="[20, 50, 100, 150]"
        layout="total,sizes,prev, pager, next"
        style="margin-top: 10px"
        v-model:page-size="request.size"
        v-model:page-count="page.pages"
        v-model:current-page="request.current"
        @current-change="loadRecord"
        @size-change="loadRecord"
      />
    </el-card>
    <el-dialog
      v-model="uiControl.dialogVisible"
      :title="t('fields.newMember')"
      append-to-body
      width="900px"
    >
      <el-table
        :resizeable="true"
        :data="newMemberPage.records"
      >
        <el-table-column
          prop="loginName"
          :label="t('fields.loginName')"
          align="left"
        />
        <el-table-column
          prop="telephone"
          :label="t('fields.telephone')"
        />
        <el-table-column
          prop="regTime"
          :label="t('fields.registerTime')"
        >
          <template #default="scope">
            <span
              v-formatter="{
                data: scope.row.regTime,
                formatter: 'YYYY/MM/DD HH:mm:ss',
                type: 'date',
              }"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="newMemberPage.total"
        :page-sizes="[20, 50, 100, 150]"
        layout="total,sizes,prev, pager, next"
        style="margin-top: 10px"
        v-model:page-size="newMemberRequest.size"
        v-model:page-count="newMemberRequest.pages"
        v-model:current-page="newMemberRequest.current"
        @current-change="loadNewRegisterMember"
        @size-change="loadNewRegisterMember"
      />
    </el-dialog>
  </div>
</template>
<script setup>

import { reactive } from 'vue'
import moment from 'moment'
import {
  queryPh1DailySummary,
  queryPh1NewRegister,
} from '../../../api/affiliate-daily-summary'
import { useI18n } from 'vue-i18n'
import { getShortcuts } from '@/utils/datetime'

const { t } = useI18n()
const fixedValue = reactive({
  siteId: 16,
  loginName: "tuiguang1",
  affiliateId: "1838090347124301826"
})

const shortcuts = getShortcuts(t)
const startDate = new Date()
startDate.setTime(
  moment(startDate)
    .startOf('month')
    .format('x')
)
const defaultStartDate = convertDate(startDate)
const defaultEndDate = convertDate(new Date())
const affiliateNames = reactive({
  list: [],
})

const request = reactive({
  size: 20,
  current: 1,
  siteId: null,
  recordTime: [defaultStartDate, defaultEndDate],
  loginNameList: null,
})

const newMemberRequest = reactive({
  size: 20,
  current: 1,
  siteId: null,
  regTime: null
})

function convertDate(date) {
  return moment(date).format('YYYY-MM-DD')
}

function resetQuery() {
  request.recordTime = [defaultStartDate, defaultEndDate]
  request.loginNameList = null
  request.affiliateCode = null
}

const page = reactive({
  pages: 0,
  records: [],
  total: 0,
  loading: false,
})

const uiControl = reactive({
  dialogVisible: false
})

const newMemberPage = reactive({
  pages: 0,
  records: [],
  loading: false,
  affiliateId: null
})

function checkQuery() {
  const requestCopy = { ...request }
  const query = {}
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value
    }
  })
  query.siteId = fixedValue.siteId
  query.loginName = fixedValue.loginName
  if (request.recordTime !== null) {
    if (request.recordTime.length === 2) {
      query.recordTime = request.recordTime
      query.recordTime[0] =
        moment(query.recordTime[0]).format('YYYY-MM-DD') + ' 00:00:00'
      query.recordTime[1] =
        moment(query.recordTime[1]).format('YYYY-MM-DD') + ' 23:59:59'
      query.recordTime = query.recordTime.join(',')
    }
  }
  if (request.loginNameList === null || request.loginNameList.length === 0) {
    query.loginNameList = affiliateNames.list.join(',')
  } else {
    query.loginNameList = request.loginNameList.join(',')
  }

  return query
}

async function loadRecord() {
  page.loading = true
  const query = checkQuery()
  const { data: ret } = await queryPh1DailySummary(query)
  // const { data: ret1 } = await queryPh1DailySummaryTotal(query)

  // total.data = ret1
  page.pages = ret.pages
  page.records = ret.records
  page.total = ret.total
  page.loading = false
}

function getSummaries(param) {
  const { columns } = param
  var sums = []
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = t('fields.total')
    } else if (index >= 1) {
      var prop = column.property
      if (index === 1 || index === 2 || index === 4) {
        const pageRowCount = Number(page.records.reduce((sum, row) => {
          return sum + Number(row[prop])
        }, 0))
        sums[index] = pageRowCount
      } else {
        const pageRowCount = Number(page.records.reduce((sum, row) => {
          return sum + Number(row[prop])
        }, 0))
        sums[index] =
          '$ ' +
          parseFloat(pageRowCount).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      }
    }
  })

  return sums
}

function showDialog(recordTime) {
  uiControl.dialogVisible = true
  newMemberRequest.regTime = recordTime
  loadNewRegisterMember()
}

async function loadNewRegisterMember() {
  newMemberPage.loading = true
  const requestCopy = { ...newMemberRequest }
  const query = {}
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value
    }
  })

  query.siteId = fixedValue.siteId
  query.affiliateId = fixedValue.affiliateId
  if (newMemberRequest.regTime !== null) {
    query.regTime = moment(newMemberRequest.regTime).format('YYYY-MM-DD') + ' 00:00:00,' + moment(newMemberRequest.regTime).format('YYYY-MM-DD') + ' 23:59:59';
  }
  const { data: ret } = await queryPh1NewRegister(query)

  newMemberPage.pages = ret.pages
  newMemberPage.records = ret.records
  newMemberPage.total = ret.total
  newMemberPage.loading = false
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  float: left;
  width: 100%;
  display: block;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.btn-group {
  margin-top: 15px;
  display: inline-flex;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-pagination {
  display: inline-block;
}

.table-footer {
  margin-top: 15px;
  margin-right: 20px;
  float: right;
  font-size: small;
}

.el-progress--line {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 430px;
}

.el-result {
  padding: 0;
}
</style>
