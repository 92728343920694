
export const UserMutationTypes = {
  SET_ID: 'SET_ID',
  SET_LOGIN_NAME: 'SET_LOGIN_NAME',
  SET_REAL_NAME: 'SET_REAL_NAME',
  SET_SITE_ID: 'SET_SITE_ID',
  SET_SITE_CODE: 'SET_SITE_CODE',
  SET_AFFILIATE_LEVEL: 'SET_AFFILIATE_LEVEL',
  SET_TOKEN: 'SET_TOKEN',
  SET_LOGIN_USER: 'SET_LOGIN_USER',
  SET_AVATAR: 'SET_AVATAR',
  SET_INTRODUCTION: 'SET_INTRODUCTION',
  SET_ROLES: 'SET_ROLES',
  SET_EMAIL: 'SET_EMAIL'
}
