export default {
  common: {
    title: '代理系统',
    logout: '登出',
    changePassword: '更改密码',
    login: '登录',
    verification_title: '安全验证, 请依次点击：',
    verification_drag_title: '安全验证, 请拖动验证',
    signup: '注册',
    username: '用户名',
    loginnow: '立即登录',
    password: '密码',
    apply: '申请',
    verificationcode: '验证码',
    verify_success: '验证成功',
    refresh: '刷新',
    submit: '提交',
    register_affi: '注册代理',
    back_login: '回到登录',
    contact_us: '联系我们',
    affiliateaccountcanonlycontainnumchar:
      '代理账号只能包含下划线（_），数字和字母。',
    confirmpassword: '密码确认',
    affiliateaccount: '合营账户',
    forgetpass: '忘记密码',
    zhuanshukefufuwu: '专属客服服务',
    askus: '咨询',
    download: '下载',
    copy: '复制',
    copied: '已复制',
    email: '合营部电邮',
    qq: '合营QQ',
    skype: '合营部Skype',
    paopao: '泡泡',
    zalo: 'Zalo',
    telegram: 'Telegram',
  },
  depositStatus: {
    SUCCESS: '存款成功',
    SUPPLEMENT_SUCCESS: '存款补单成功',
    CLOSED: '已关闭',
    PENDING: '待存款',
  },
  withdrawStatus: {
    ALL: '全部',
    SUCCESS: '支付成功',
    FAIL: '支付失败',
    APPLY: '申请中',
    STEP_1: '审核中',
    STEP_2: '待支付',
    STEP_3: '支付中',
    AUTOPAY: '自动支付中',
    PENDING: '中止',
    REJECT: '驳回',
    SENDING: '发送中',
    WAITING_CALLBACK: '自动支付中',
    PAYING: '支付',
    WAITING_AUTO_PAY: '待自动支付',
  },
  withdrawConfirmStatus: {
    0: '未确认',
    1: '已到账',
  },
  questions: {
    mothersName: '您母亲的姓名是？',
    mothersBirthday: '您母亲的生日是？',
    dadsName: '您父亲的姓名是？',
    dadsBirthday: '您父亲的生日是？',
    spouseBirthday: '您配偶的生日是？',
    cityBorn: '您出生的城市是？',
    highSchool: '您的中学校名是？',
    elementarySchool: '您的小学校名是？',
    firstPetName: '您第一只宠物的名是？',
    firstCar: '您拥有第一部车的品牌是？',
  },
  gameType: {
    SLOT: '电子',
    LIVE: '真人',
    FISH: '捕鱼',
    SPORT: '体育',
    ESPORT: '电竞',
    POKER: '棋牌',
    LOTTERY: '彩票',
    MINIGAME: '小游戏',
  },
  result: {
    WIN: '赢',
    LOSS: '输',
    DRAW: '和',
  },
  betStatus: {
    UNSETTLED: '未结算',
    SETTLED: '已结算',
    CANCEL: '已取消',
  },
  transferType: {
    DEPOSIT: '额度代存',
    COMMISSION: '佣金代存',
  },
  creditFlowType: {
    Withdraw: '提款',
    Transfer: '转账',
    Dividen: '红利',
    Deposit: '存款',
    Agent_Transfer: '代理转账',
    Agent_Deposit: '代理代存',
    COMMISSION_WALLET_WITHDRAWAL: '提款',
    COMMISSION_WALLET_AFFILIATE_DEPOSIT: '代理代存',
    COMMISSION_WALLET_SETTLEMENT: '佣金结算',
    DEPOSIT_WALLET_DEPOSIT: '存款',
    DEPOSIT_WALLET_AFFILIATE_DEPOSIT: '代理代存',
    DEPOSIT_WALLET_AMOUNT_ADJUST: '额度调整',
  },
  referralLink: {
    affiliateWebPlatformLink: 'PC端推广链接',
    affiliateH5PlatformLink: 'H5版推广链接（推荐使用）',
    affiliateWXShortLink: '防封短链 (微信)',
    affiliateQQShortLink: '防封短链 (QQ)',
    affiliateZALOShortLink: '防封短链 (ZALO)',
    affiliateLongLink: '长连接',
    affiliateWXQRLink: '防封二维码 (微信)',
    affiliateQQQRLink: '防封二维码 (QQ)',
    affiliateZALOQRLink: '防封二维码 (ZALO)',
    affiliateLongQRLink: '长连接二维码',
    affiliateDownloadQRtoLocal: '下载到本地',
    affiliateScanMe: '扫一扫，手机查看',
    copiedWXShortUrl: '已帮您复制微信防封短链',
    copiedQQShortUrl: '已帮您复制QQ防封短链',
    copiedLink: '复制成功',
    downloadQRCode: '下载二维码',
    affiliateShortLink: '短域名',
  },
  commissionInfo: {
    commissionInfo: '佣金说明',
    affiliateCommissionInfo: '代理佣金说明',
    commissionCalculator: '佣金模拟器',
    monthlyTotalEffectiveTurnover: '本月总输赢(￥)',
    pleaseInsertMonthlyTotalEffectiveTurnover: '请输入本月总输赢(￥)',
    monthlySettlement: '本月平账',
    pleaseInsertMonthlySettlement: '请输入本月平账',
    monthlyDividen: '本月红利',
    pleaseInsertMonthlyDividen: '请输入本月红利',
    platformFee: '平台费用',
    monthlyTotalDeposit: '会员本月存款(￥)',
    pleaseInsertMonthlyTotalDeposit: '请输入会员本月存款(￥)',
    monthlyTotalWithdrawal: '会员本月提款(￥)',
    pleaseInsertMonthlyTotalWithdrawal: '请输入会员本月提款(￥)',
    totalCommissionAbleClaim: '本月可获取佣金(￥)',
    calculate: '计算',
    commissionCalculatorNote:
      '注：模拟器按照返水和红利均值预估，场馆选择默认会员都在统一场馆参与游戏的情况进行计算，结果仅供参考。',
    commissionRateInfo: '佣金比例说明',
    commissionRateDescription: '代理的佣金比例由本月总输赢和活跃人数两项条件决定，同时满足时获取对应等级的佣金比例',
    activeMemberRequirement: '活跃人数：当月存款>=100或有效投注>=500；',
    level: '级别',
    companyMonthlyProfit: '公司本月总输赢',
    activeNumberRequirement: '新增/ 活跃数量最低要求',
    commissionRate: '佣金比例',
    commissionCalculateFormula: '佣金计算方式',
    commissionFormula: '佣金 = 冲正后净输赢 * 佣金比例 + 佣金调整 + 佣金补调；',
    correctionNetProfitFormula: '冲正后净输赢 = 当月净输赢 + 上月结余；',
    monthlyNetProfitFormula: '当月净输赢 = 当月总输赢 - 红利 - 返水 - 场馆费 - 账户调整 + 补单输赢 - 存提手续费；',
    bonusFormula: '红利 = 代理下线会员当月领取的平台发放红利的总和；',
    rebateFormula: '返水 = 代理下线会员当月领取平台返水的总和；',
    platformFeeFormula: '场馆费 = 各游戏场馆总输赢 * 平台费率，但只有当月产生盈利，平台才会收取场馆费；',
    adjustmentFormula: '账户调整 = 当会员流水异常时，站点管理员对会员进行账户调整；',
    supplementWinLossFormula: '补单输赢 = 上月实时数据净输赢 - 上月发放佣金净输赢；',
    depositFeeFormula: '存款手续费 = 下级会员存款金额 * 存款费率 + 代理额度充值金额 * 存款费率；',
    withdrawalFeeFormula: '提款手续费 = 下级会员的取款金额 * 取款费率；',
    commissionAdjustmentNote: '佣金补调：当月佣金发放后，根据代理参与的相关活动，或其它原因核实后进行补调。',
    commissionNote: '注：请谨记任何使用无诚信的方法以骗取佣金将会被永久冻结账号，并终止合作关系，所有佣金一概不予发放。',
  },
  sortType: {
    ASC: '顺序',
    DESC: '逆序',
  },
  rebateStatus: {
    PENDING: '审核中',
    IN_PROGRESS: '处理中',
    DISTRIBUTED: '已发送',
    CANCEL: '已取消',
  },
  fields: {
    account: '账号',
    accountInfo: '账号信息',
    activeMember: '活跃会员',
    activePlayer: '活跃玩家',
    activeUsers: '活跃会员',
    add: '新增',
    addBankCard: '新增银行卡',
    addVirtualCard: '新增虚拟卡',
    adjust: '调整',
    adjustAmount: '调整金额',
    adjustment: '线下优惠',
    adjustReason: '调整原因',
    adjustType: '平账类型',
    affiliate: '代理',
    affiliateAccount: '代理账号',
    affiliateCode: '合营代码',
    affiliateDeposit: '额度充值',
    affiliateInfo: '代理信息',
    affiliateLevel: '代理级别',
    affiliateStatus: '代理状态',
    affiliateWithdraw: '银行提款',
    affiliateWithdrawRecord: '银行提款记录',
    amount: '金额',
    amountOfFirstDeposit: '首充金额',
    answerOne: '答案一',
    answerTwo: '答案二',
    answerThree: '答案三',
    answerSecurityQuestion: '请回答全部安全密保问题',
    appLink: '代理后台app下载链接',
    balance: '余额',
    bank: '银行卡',
    bankCard: '银行卡',
    bankId: '银行ID',
    bankName: '银行',
    bankCode: '银行',
    bet: '投注金额',
    betMembers: '下注会员数',
    betRecord: '投注记录',
    betRecordDetails: '注单详情',
    betTime: '投注时间',
    bind: '绑定',
    bindAccountList: '绑定账户列表',
    bindBankCardList: '绑定银行卡列表',
    bindCryptoList: '绑定虚拟账户列表',
    binded: '已绑定',
    bindEWalletList: '绑定电子钱包列表',
    bindSecurityQn: '设置安全密保',
    bindWithdrawPw: '设置支付密码',
    bonus: '红利',
    bulk_read: '批量已读',
    bulk_delete: '批量删除',
    cancel: '取消',
    cardAccount: '银行卡账号',
    cardAddress: '银行卡地址',
    cardNumber: '银行卡号码',
    changeWithdrawPw: '更改支付密码',
    clearingSum: '结算总和',
    commission: '佣金',
    gamecommission: "游戏平台佣金",
    commissionBalance: '佣金余额',
    commissionPercent: '分红 %',
    commissionRate: '佣金比例',
    commissionReport: '佣金明细',
    commissionTransfer: '佣金代存',
    commissionWallet: '佣金钱包',
    companyProfit: '公司利润',
    confirm: '确认',
    confirmNewPassword: '确认新密码',
    confirmStatus: '确认状态',
    confirmWithdraw: '确认提款',
    copy: '复制',
    createAffiliate: '新增代理',
    creditFlow: '账变明细',
    creditFlowAmount: '账变金额（元）',
    creditFlowId: '序号',
    creditFlowType: '账变类型',
    creditFlowDate: '时间',
    creditFlowBalance: '余额（元）',
    createTime: '时间',
    crypto: '数字货币',
    currentPassword: '当前密码',
    deduct: '扣除',
    deposit: '存款',
    depositAmount: '存款金额',
    depositBettingAmount: '存款/投注',
    depositCount: '存款次数',
    depositDate: '订单提交时间',
    depositRecord: '充值记录',
    depositUsers: '充值会员数',
    depositWallet: '代存钱包',
    domainAffiliate: '专属域名',
    domainApp: '移动端专属域名',
    domainWeb: 'PC端专属域名',
    downlineAffiliate: '下级代理',
    downlineCommission: '下级分红',
    downlineCommissionRate: '下级分红比率',
    downlineMember: '下级会员',
    downlineProfit: '下线盈利',
    download: '下载',
    edit: '修改',
    editAffiliate: '编辑代理',
    editRealName: '编辑真实名字',
    email: '邮箱',
    endDate: '结束日期',
    enquire: '咨询',
    enterTheWithdrawalAmount: '请输入提款金额',
    estimatedAffiliateCommission: '预计代理佣金',
    estimatedMemberCommission: '预计会员佣金',
    ewallet: '电子钱包',
    eventClickCount: '页面点击',
    finalSum: '最终佣金',
    finishDate: '订单处理时间',
    firstDepositAmount: '首充金额',
    firstDepositUsers: '首充人数',
    ftd: '首存',
    gameName: '游戏名称',
    gameType: '游戏类型',
    lastLoginTime: '最近登录时间',
    lastMonth: '上月',
    lastMonthTotal: '累计上月',
    lastWeek: '上周',
    link: '邀请链接',
    loginName: '用户名',
    loginPassword: '登录密码',
    member: '会员',
    memberBetRecords: '会员投注记录',
    memberCommission: '会员分红',
    memberInfo: '会员信息',
    memberProfit: '代理盈利',
    memberProfitDownlineProfitUnsettleCommission: '代理盈利/下线盈利/上月结余',
    memberType: '会员类型',
    minActiveMemberCount: '最低活跃会员数',
    month: '月份',
    monthlyAffiliateCommission: '本月代理佣金',
    monthBeforeLastTotal: '累计上上月',
    monthlyMemberCommission: '本月会员佣金',
    newMemberCount: '新成员计数',
    newMember: '新注冊会员',
    activeMemberCount: '活跃成员计数',
    totalRegisterCount: '总注册数',
    myAccount: '我的账号',
    realName: '真实名字',
    name: '名字',
    netProfit: '净盈利',
    newPassword: '新密码',
    newUsers: '新注册会员人数',
    ngr: 'NGR',
    noData: '查无数据',
    actions: '操作',
    operate: '操作',
    operationalData: '运营数据',
    password: '密码',
    paymentFee: '存提手续费',
    paymentMethod: '支付方式',
    paymentName: '充值渠道',
    payout: '派彩金额',
    personal: '个人资料',
    personalInfo: '个人信息',
    privilegeSerialNumber: '编码',
    privilegeRecord: '优惠记录',
    privilegeName: '优惠名',
    platform: '平台',
    platformFee: '平台费',
    profit: '总盈利',
    questionOne: '问题一',
    questionTwo: '问题二',
    questionThree: '问题三',
    readed: '已读',
    rebate: '返水',
    recordTime: '记录时间',
    reenterPassword: '重新输入密码',
    referralCode: '推荐码',
    referralLink: '会员推广链接',
    regenerate: '重新生成',
    registerTime: '注册时间',
    reset: '重置',
    result: '结果',
    revenueShare: '收入',
    revenueShareRate: '收入比率',
    rollover: '流水倍数',
    search: '搜索',
    secondLevelAffiliateCommission: '二级代理佣金',
    securityInfo: '安全信息',
    securityQuestion: '安全密保',
    select: '选择',
    selectACard: '选择银行卡',
    selectBank: '选择银行',
    selectBankCard: '选择银行卡',
    selectUsdtWallet: '请选择虚拟钱包账号',
    serialNumber: '订单号',
    paymentType: '类型',
    settleTime: '结算时间',
    settleView: '查看',
    settlePay: '发放',
    settleEdit: '调整',
    site: '站点',
    startDate: '开始日期',
    status: '状态',
    subtotal: '小计',
    systemAlert: '系统提示',
    affiliateSuccessSubmit:
      '尊敬的合作伙伴，您的资料提交成功，我们的代理专员会在24小时内告知您审核结果，如有疑问请联系我们代理专员或在线客服，谢谢。',
    systemAnnouncement: '系统公告',
    telephone: '电话号码',
    thirdLevelAffiliateCommission: '三级代理佣金',
    thisYear: '今年',
    thisThreeMonths: '这三个月',
    thisMonth: '本月',
    thisWeek: '本周',
    title: '标题',
    today: '今日',
    totalBet: '总投注额',
    totalCompanyProfit: '总公司利润',
    totalCommission: '总分红',
    totalCommissionProfit: '佣金',
    totalDeposit: '总存款',
    totalDownlineAffiliate: '总直属代理',
    totalDownlineMember: '总直属会员',
    totalPayout: '总派彩额',
    totalTransfer: '总代存',
    totalWithdraw: '总提款',
    transactionId: '投注单号',
    transfer: '额度代存',
    transferAmount: '代存金额',
    transferDate: '代存时间',
    transferIn: '转入',
    transferOut: '转出',
    transferRecord: '代存记录',
    transferType: '代存类型',
    transferUsers: '代存会员数',
    unread: '未读',
    upperName: '所属上级',
    usdtWallet: '钱包账号',
    usdtWalletAddress: '钱包地址',
    unsettleCommission: '上月结余',
    updateBy: '更新人',
    viewDetails: '查看详情',
    vipLevel: 'VIP等级',
    visitsNo: '访问量',
    winLoss: '盈亏',
    withdraw: '提款',
    withdrawDate: '提款日期',
    withdrawAmount: '提款金额',
    withdrawCount: '提款次数',
    withdrawPassword: '支付密码',
    withdrawPayoutBonus: '提款/派彩/奖金/调整',
    withdrawalAmount: '提款金额',
    yesterday: '昨日',
    memberTag: '会员标签',
    memberTagDescription: '请输入10字符内的名称',
    tagSetting: '标签设置',
    createTag: '新增标签',
    editTag: '编辑标签',
    memberAmount: '会员数量',
    delete: '删除',
    sequence: '序号',
    back: '返回',
    all: '全部',
    yes: '有',
    no: '没有',
    more: '更多',
    remark: '会员备注',
    bonusRecord: '红利记录',
    ftdTime: '首存时间',
    validBet: '有效投注',
    unAssigned: '未分配',
    batchEditTag: '批量编辑标签',
    personalReport: '个人报表',
    platformFeePercentage: '平台費率',
    rolloverAmount: '流水',
    privilegeType: '优惠类型',
    personalCommission: '个人佣金',
    payTime: '发放时间',
    payStatus: '发放状态',
    accountAdjustment: '账户调整',
    commissionAdjustment: '佣金调整',
    image: '图片',
    imageTitle: '图片标题',
    imageType: '图片类型',
    uploadTime: '更新日期',
    imageSize: '图片尺寸',
    downloadTime: '下载次数',
    generateQR: '生成二维码',
    qrCode: '二维码',
    textInfo: '文字信息',
    qrCodeColor: '二维码颜色',
    qrCodeBgColor: '二维码背景颜色',
    qrCodeLink: '二维码链接',
    qrCodePreview: '二维码预览',
    text: '文字',
    textColor: '文字颜色',
    textBgColor: '文字背景颜色',
    textPreview: '文字预览',
    preview: '预览',
    custom: '自定义',
    packHistory: '打包记录',
    packType: '打包项目',
    selectSystem: '选择系统',
    appVersion: '当前版本号',
    appName: '应用名',
    appIcon: '应用图标',
    packNow: '立即打包',
    upload: '上传',
    packDate: '打包时间',
    osType: '系统类型',
    buildStatus: '构建状态',
    apkType: 'APK 种类',
    normal: '正常',
    newKey: '防毒',
    detail: '详情',
    packSize: '包大小',
    packDownloadUrl: '渠道包链接',
    unchanged: '无修改',
    unbind: '解绑',
    depositWithdrawalProfit: '存提利润',
    registerCount: '新注册用户人数',
    ftdCount: '首存人数',
    ftdAmount: '首存金额',
    indBet: '下注',
    indProfit: '下注派彩利润',
    last3Months: '上3个月',
    total: '总计',
    betAmount: '投注金额',
    payoutAmount: '派彩金额',
    totalMemberDepositAmount: '总充值金额',
    totalMemberDepositCount: '总充值人数',
    bonusAmount: '奖励金额',
    rebateAmount: '返点金额',
    indBonusAmount: '奖励金额',
    indRebateAmount: '返点金额',
    indAdjustAmount: '资金修正金额',
    grossProfit: '毛利润',
    totalBetMemberCount: '总下注人数',
    totalDepositMemberCount: '总充值人数',
    orderBy: '排序字段',
    sortType: '排序方式',
    exclusiveDomain: '专属域名',
    pcWay: 'PC端',
    mobileWay: '移动端',
    forgetPassword: '忘记密码',
    authenticator: '身份验证器',
    withdrawRecord: '银行提款',
    createMember: '新增会员',
    shareRatio: '佣金比例',
    rebateWallet: '返水钱包',
    affiliateTotalRebate: '代理返水',
    myRebate: '我的返水',
    totalWinLoss: '总输赢',
    todayDeposit: '今日总存款',
    todayWithdrawal: '今日总提款',
    depositWithdrawalDiff: '存提差',
    recommenderCode: '邀请码',
    memberShareRatio: '玩家返水比例',
    editShareRatio: '编辑返水',
  },
  google: {
    google_auth_menu: '谷歌验证',
    download_install: '下载安装',
    add_key: '添加密钥',
    bind_verify: '绑定验证',
    popup_title: '身份验证器',
    popup_msg:
      '身份验证器是谷歌的一款动态口令工具,每隔30秒自动更新。在代理\n' +
      '        后台进行转账、提现、安全设置等敏感操作需要进行校验身份时,输\n' +
      '        入这6位身份验证码即可。',
    popup_hint: '身份验证器必须与代理账户配合使用。',
    i_know: '我知道了',
    download_way: '下载方法：',
    download_through_way: '1.通过下述地址下载：',
    ios_download: 'iOS下载',
    android_download: 'Android下载',
    ios_scan_download: 'IOS扫码下载',
    android_scan_download: 'Android扫码下载',
    if_cannot_download_you_can: '2.倘若无法下载，您可以在苹果商店搜索"',
    or_search_in_google: '"，或安卓应用商店搜索"',
    download_and_install: '身份验证器"下载安装。',
    keyin_pass: '输入密码并进行下一步',
    keyin_your_password: '输入登录密码',
    next_step: '下一步',
    tips_installed: '小提示：已下载安装，点击“下一步”继续即可。',
    qr_code: '二维码',
    secret_key: '密钥',
    add_step: '添加步骤',
    add_step_desc:
      '打开谷歌身份验证器，点击右下角的“+”，选择“手动输入密钥”，填入任意账户和上述秘钥绑定\n' +
      '      (扫描二维码可以自动添加)',
    check_example: '查看示例图',
    back: '返回',
    small_tips: '小提示：',
    small_tips_info_1:
      '1. 手机丢失或卸载身份验证后，密钥能够帮助您找回身份验证器，请妥善保管;',
    small_tips_info_2:
      '2.为了您的账户安全，绑定时请勿标注代理账户及代理后台地址。',
    keyin_6_digit_google: '请输入6位谷歌验证码',
    auth_code: '身份验证码',
    bind_now: '立即绑定',
    please_enter_password: '请输入您的密码',
    added_success: '谷歌验证码成功添加。',
    if_not_google_auth_then: '若未绑定Google Authenticator 则无需填写',
    google_auth_code: '请输入6位数谷歌验证码',
  },
  forgetPassword: {
    verifyAuth: '身份验证',
    verifyQues: '安保问题',
    resetPassword: '密码重设',
    verify: '验证',
    answer: '答案',
    submit: '提交',
    reset: '重设',
    messageAuth: '请输入用户名和谷歌验证码',
    messageQues: '请回答以下安保问题',
    messageReset: '请重设新密码',
    noSecurityQuestionSet: '此账号没有设置安保问题，请联络客服',
    resetSuccess: '密码重置成功',
  },
  message: {
    addSuccess: '新增成功',
    adjustSuccess: '平账成功',
    bindBankCard: '请先绑定银行卡',
    bindUsdtWallet: '请先绑定虚拟币钱包',
    chineseCharacters: '请输入中文字符',
    commissionPaySuccess: '佣金发放成功',
    confirmToAdjust: '请确认是否进行调整？一旦进行调整将无法再更改',
    confirmToPay: '请确认是否发放佣金?',
    deleteSuccess: '删除成功',
    domainAppCopied: '移动端专属域名已复制到剪贴板',
    domainWebCopied: 'PC端专属域名已复制到剪贴板',
    editSuccess: '编辑成功',
    inputRealName: '会员真实名字未填',
    emailFormat: '请输入正确的邮箱格式',
    inputChineseCharacters: '请输入中文字符',
    inputDigits: '请输入数字',
    inputPassword: '请输入密码',
    inputPasswordAgain: '请再输入密码',
    length6To12: '字符串必须为 6 至 12 字',
    lengthShouldBe: '长度应为 ',
    passwordLength: '密码必须多于 6 字并少于 12 字',
    redirectBankDeposit:
      '您已被重定向至您的特定银行以继续存款。存款成功后会在此处体现。',
    reenterPassword: '请重新输入密码',
    referralLinkCopied: '推荐链接已复制到剪贴板',
    remaining: '剩余次数',
    requiredAffiliateLevel: '请输入代理级别',
    required_signup_account: '代理账号不能为空',
    required_6_to_12: '由6-12位数字或字母组成',
    requried_password: '登录密码不能为空',
    required_captcha: '验证码不能为空',
    required_4_digits: '由4位数字组成',
    required_confirm_pwd: '密码确认不能为空',
    required_same_with_password: '与登录密码不一致',
    requiredAmount: '请输入金额',
    requiredAnswer: '请输入答案',
    requiredCardAccount: '请输入银行卡账号',
    requiredCardAddress: '请输入银行卡地址',
    requiredCardNumber: '请输入银行卡号',
    requiredCommission: '请输入佣金',
    requiredEmail: '请输入邮箱',
    requiredLoginName: '请输入用户名',
    requiredMemberType: '请选择会员类型',
    requiredOldPassword: '请输入当前密码',
    requiredPassword: '请输入密码',
    requiredRealName: '请输入姓名',
    requiredRevenueShare: '请输入收入',
    requiredRollover: '请输入流水倍数',
    requiredTelephone: '请输入电话号码',
    requiredTransferAmount: '请输入代存金额',
    requiredUsdtWallet: '请输入虚拟钱包账号',
    requiredUserName: '请输入用户名',
    requiredWithdrawPassword: '请输入支付密码',
    selectAQuestion: '请选择问题',
    selectBankCard: '请选择银行卡',
    selectUsdtWallet: '请选择虚拟钱包账号',
    setSecurityQn: '请先设置安全密保',
    setSecAndPw: '请先设置安全密保与支付密码',
    setWithdrawPassword: '请先设置支付密码',
    singleLimit: '单笔限额',
    success: '成功',
    times: '次',
    twoPasswordNotMatch: '重新输入密码不相同',
    unbindConfirm: '请确认是否要解绑 ',
    validateAdjustAmountRequired: '平账金额必填',
    validateAdjustReasonRequired: '平账原因必填',
    validateAdjustTypeRequired: '平账类型必填',
    validateBankCardNumber: '银行卡号只能包含数字',
    validateCommission: '佣金必须介于 0 至 1 之间',
    validateNumberOnly: '请只输入数字',
    validateNumberMoreThanOne: '请输入大于 1 的整数',
    validateRevenueShare: '收入必须介于 0 至 1 之间',
    validateUsdtWallet: '虚拟钱包账号只能包含数字及英文字母',
    withdrawalToday: '今日提款',
    inputUSDTAmount: '输入USDT金额',
    inputAmount: '输入存款金额',
    selectAmount: '选择存款金额',
    minDepositeAmount: '最低存款',
    maxDepositeAmount: '最高存款',
    currencyRates: '实时汇率',
    understand: '理解',
    DepositCompleted: '已存款',
    depositNotification1: '您将被重定向到您的银行页面以完成存款。',
    depositNotification2: '如果成功，您将在此页面上收到通知。',
    validateTagDescriptionRequired: '会员标签必填',
    confirmDelete: '请确认是否要删除这些数据, 此操作不可撤销',
    remarkMessage: '请输入200字符内的会员备注',
    validateTagDescriptionLength: '标签必须多于 1 字并少于 10 字',
    packRequestInQueue: '渠道打包请求正在等待中',
    channelPackCancelSuccess: '渠道打包已成功取消',
    channelPackUrlCopied: '渠道打包链接已复制到剪贴板',
    validateAppNameRequired: '应用名必填',
    validateAppIconRequired: '应用图标必填',
    validateShareRatioFormat: '佣金比例必须介于 0 至 1 之间',
    depositShouldBetween: '存入金额介于 ',
    requiredPositiveInteger: "金额应为正数",
  },
  menu: {
    undefined: '',
    Dashboard: '首页',
    'Downline Info': '下级信息',
    Member: '会员',
    Affiliate: '代理',
    AffiliateSummary: '下级代理总结',
    gameRecord: '游戏记录',
    financeCentre: '财务中心',
    'Bet Record': '会员投注记录',
    'Deposit Record': '会员充值记录',
    Statistics: '统计',
    'Game Stats': '游戏统计',
    'Affiliate Daily Report': '代理每日报表',
    'Affiliate Center': '代理中心',
    Deposit: '额度充值',
    'Bind Bank Cards': '绑定银行卡',
    'Bank Withdrawal': '银行提款',
    Transfer: '代理代存',
    'Referral Centre': '推广中心',
    'Referral Management': '推广管理',
    'Referral Link': '会员推广链接',
    'Referral Material': '推广素材',
    'Affiliate Domain': '专属域名',
    'Personal Center': '个人中心',
    'Settlement Center': '佣金',
    'Finance Report': '财务报表',
    'Settlement Report': '佣金报表',
    'Channel Pack': '渠道打包',
    contactUs: '联系我们',
    'Daily Detail': '日报详情',
    'Daily Summary': '每日全总览',
    Withdraw: '银行提款',
    'Rebate Report': '返水报表',
    AffiliateOwnSummary: '本代理总结',
  },
  posterType: {
    OVERALL: '综合推广图',
    APP: 'APP推广图',
    SPONSOR: '赞助推广图',
    GIFT: '赠送推广图',
    COMPETITION: '赛事推广图',
    EVENT: '活动推广图',
    CRYPTO: '虚拟币推广图',
    AFFILIATE: '代理福利图',
  },
  packStatus: {
    IN_QUEUE: '等待中',
    IN_PROGRESS: '进行中',
    SUCCESS: '成功',
    FAIL: '失败',
    CANCEL: '取消',
  },
  appType: {
    ALL_SITE: '全站',
    SPORT: '体育',
    ESPORT: '电竞',
  },
  member: {
    status: {
      normal: '正常',
      frozen: '冻结',
    },
  },
  affiliate: {
    level: {
      JUNIOR_AFFILIATE: '五级代理',
      SUB_AFFILIATE: '四级代理',
      AFFILIATE: '三级代理',
      SUPER_AFFILIATE: '二级代理',
      MASTER_AFFILIATE: '一级代理',
    },
    status: {
      APPLY: '申请',
      NORMAL: '正常',
      DISABLE: '禁用',
    },
  },
  osType: {
    ANDROID: '安卓',
    IOS: 'IOS',
  },
  affiliateShareRatio: {
    AGENT_SHARE_BET_RATIO_FISH: '有效投注佣金-捕鱼',
    AGENT_SHARE_BET_RATIO_SLOT: '有效投注佣金-老虎机',
    AGENT_SHARE_BET_RATIO_ESPORT: '有效投注佣金-电竞',
    AGENT_SHARE_BET_RATIO_LIVE: '有效投注佣金-真人',
    AGENT_SHARE_BET_RATIO_SPORT: '有效投注佣金-体育',
    AGENT_SHARE_PROFIT_RATIO_FISH: '净盈利佣金-捕鱼',
    AGENT_SHARE_PROFIT_RATIO_SLOT: '净盈利佣金-老虎机',
    AGENT_SHARE_PROFIT_RATIO_ESPORT: '净盈利佣金-电竞',
    AGENT_SHARE_PROFIT_RATIO_LIVE: '净盈利佣金-真人',
    AGENT_SHARE_PROFIT_RATIO_SPORT: '净盈利佣金-体育',
  },
  error: {
    400: '请求无效',
    403: '禁止访问',
    405: '方法不被允许',
    500: '系统错误',
    501: '请求ID重复',
    502: '无法找到伺服器',
    504: '请求太频繁',
    601: '令牌验证失败',
    602: '令牌已逾期',
    603: '无法找到令牌',
    604: '账号已登录',
    707: '余额不足',
    709: '代理佣金余额不足',
    800: '验证码失败',
    801: '验证码已失效',
    901: '代理记录不存在',
    902: '此用户非代理',
    903: '此账号为代理',
    904: '此账号为会员',
    905: '真实名字尚未填写',
    1000: '登录名或密码错误',
    1001: '登录失败, 账号已被禁用',
    1100: '新密码不能与旧密码相同',
    1101: '旧密码不正确',
    1102: '支付密码不正确',
    1201: '答案不正确',
    1308: '余额不足',
    1311: '24小时内勿提交相同提款金额',
    13000: '域名不存在',
    14000: '用户名已被使用 : ',
    14001: '电话号码已被使用 : ',
    14002: '邮箱已被使用 : ',
    14003: '代理代码不存在 : ',
    14004: '代理代码不属于此站点 : ',
    14005: '代理佣金必须少于上线代理佣金 : ',
    14006: '代理收入必须少于上线代理收入 : ',
    14007: '代理申请尚未被批准',
    14008: '代理佣金必须多余子代理佣金 : ',
    14009: '代理收入必须多余子代理收入 : ',
    14100: '代理并无此下级会员',
    14102: '会员标签已存在',
    14103: '会员标签已达上限',
    15201: '旧密码不正确',
    15202: '新密码不能与旧密码相同',
    14106: '密码不正确',
    24000: '验证失败',
    1009: '谷歌验证码输入错误',
    900: '用户不存在',
    11011: '上级游戏佣金未设置',
    11012: '上级游戏佣金未设置: ',
    11013: '下级佣金比例不能大于上级',
    11014: '下级代理的返水比例总和不能超过上级的返水比例',
    11015: '此代理没有设置返水比例',
    11016: '玩家返水比例不能超过代理返水比例',
    14012: '只能创建比自己低的代理等级',
    14013: '此代理已有下线，不能编辑更低的代理等级',
  },
}
