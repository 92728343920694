export default {
  common: {
    title: 'Hệ thống liên kết',
    logout: 'Đăng xuất',
    changePassword: 'Thay đổi mật khẩu',
    login: 'Đăng nhập',
    signup: 'Đăng ký',
    username: 'Tên Tài khoản đại lý',
    loginnow: 'Đăng nhập ngay',
    password: 'Mật khẩu',
    confirmpassword: 'Xác nhận mật khẩu',
    apply: 'Áp dụng',
    verificationcode: 'Mã xác minh',
    verification_title: 'Kiểm tra bảo mật, Vui lòng click theo từng bước：',
    verify_success: 'Xác minh thành công',
    refresh: 'Làm mới',
    submit: 'Gửi',
    register_affi: 'Đăng ký ngay',
    back_login: 'Quay lại đăng nhập',
    contact_us: 'Liên hệ chúng tôi',
    affiliateaccount: 'Tên Tài khoản đại lý',
    affiliateaccountcanonlycontainnumchar:
      'Tài khoản liên kết chỉ có thể chứa _, số và chữ cái.',
    forgetpass: 'Quên mật khẩu',
    zhuanshukefufuwu: 'Dịch vụ chăm sóc khách hàng riêng',
    askus: 'Hỏi',
    download: 'Tải xuống',
    copy: 'Sao chép',
    copied: 'Đã sao chép',
    email: 'Email',
    qq: 'QQ',
    skype: 'Skype',
    paopao: 'Bubble',
    zalo: 'Zalo',
    telegram: 'Telegram',
    24: {
      line1: 'liên hệ chúng tôi',
      line2: 'Đường dây nóng dịch vụ 7X24'
    }
  },
  depositStatus: {
    SUCCESS: 'Thành công',
    SUPPLEMENT_SUCCESS: 'Bổ sung thành công',
    CLOSED: 'Đã đóng',
    PENDING: 'Đang chờ',
  },
  withdrawStatus: {
    ALL: 'tất cả',
    SUCCESS: 'thanh toán thành công',
    FAIL: 'Thanh toán không thành công',
    APPLY: 'Áp dụng',
    STEP_1: 'đang được xem xét',
    STEP_2: 'Được trả tiền',
    STEP_3: 'Thanh toán',
    AUTOPAY: 'Đang tiến hành thanh toán tự động',
    PENDING: 'Huỷ bỏ',
    REJECT: 'từ chối',
    SENDING: 'gửi',
    WAITING_CALLBACK: 'Chờ thanh toán',
    PAYING: 'chi trả',
    WAITING_AUTO_PAY: 'Đang chờ thanh toán tự động',
  },
  withdrawConfirmStatus: {
    0: 'chưa được xác nhận',
    1: 'Đã đến',
  },
  questions: {
    mothersName: 'Tên của mẹ bạn là gì?',
    mothersBirthday: 'Sinh nhật của mẹ bạn là khi nào?',
    dadsName: 'Tên của bố bạn là gì?',
    dadsBirthday: 'Sinh nhật của bố bạn là khi nào?',
    spouseBirthday: 'Sinh nhật của vợ/chồng bạn là khi nào?',
    cityBorn: 'Bạn sinh ra ở thành phố nào?',
    highSchool: 'Bạn đã học trường trung học nào?',
    elementarySchool: 'Tên của trường tiểu học bạn đã học là gì?',
    firstPetName: 'Tên của thú cưng đầu tiên của bạn là gì?',
    firstCar: 'Hãng xe của chiếc xe đầu tiên của bạn là gì?',
  },
  gameType: {
    SLOT: 'MÁY ĐƯỢC',
    LIVE: 'TRỰC TIẾP',
    FISH: 'CÁ',
    SPORT: 'THỂ THAO',
    ESPORT: 'ESPORT',
    POKER: 'POKER',
    LOTTERY: 'XỔ SỐ',
  },
  result: {
    WIN: 'THẮNG',
    LOSS: 'THUA',
    DRAW: 'HÒA',
  },
  betStatus: {
    UNSETTLED: 'CHƯA THANH TOÁN',
    SETTLED: 'ĐÃ THANH TOÁN',
    CANCEL: 'HỦY',
  },
  transferType: {
    DEPOSIT: 'Chuyển từ Số dư',
    COMMISSION: 'Chuyển từ Số dư Hoa hồng',
  },
  creditFlowType: {
    Withdraw: 'Rút tiền',
    Transfer: 'Chuyển khoản',
    Dividen: 'Cổ tức',
    Deposit: 'Nạp tiền',
    Agent_Transfer: 'Chuyển khoản đại lý',
    Agent_Deposit: 'Nạp tiền đại lý',
    COMMISSION_WALLET_WITHDRAWAL: 'Rút tiền',
    COMMISSION_WALLET_AFFILIATE_DEPOSIT: 'Nạp tiền liên kết',
    COMMISSION_WALLET_SETTLEMENT: 'Thanh toán hoa hồng',
    DEPOSIT_WALLET_DEPOSIT: 'Nạp tiền',
    DEPOSIT_WALLET_AFFILIATE_DEPOSIT: 'Nạp tiền liên kết',
    DEPOSIT_WALLET_AMOUNT_ADJUST: 'Điều chỉnh thủ công',
  },
  referralLink: {
    affiliateWebPlatformLink: 'Liên kết đại lý PC',
    affiliateH5PlatformLink: 'Liên kết đại lý H5 (Được Khuyến Nghị)',
    affiliateWXShortLink: 'URL Chống Chặn (Wechat)',
    affiliateQQShortLink: 'URL Chống Chặn (QQ)',
    affiliateZALOShortLink: 'URL Chống Chặn (Zalo) ',
    affiliateLongLink: 'URL Bình Thường',
    affiliateWXQRLink: 'Mã QR Chống Chặn (Wechat)',
    affiliateQQQRLink: 'Mã QR Chống Chặn (QQ)',
    affiliateZALOQRLink: 'Mã QR Chống Chặn (Zalo)',
    affiliateLongQRLink: 'Mã QR Liên Kết Bình Thường',
    affiliateDownloadQRtoLocal: 'Tải Về',
    affiliateScanMe: 'Quét và Xem trên Điện Thoại Di Động',
    copiedWXShortUrl: 'Sao Chép URL Chống Chặn Wechat Thành Công',
    copiedQQShortUrl: 'Sao Chép URL Chống Chặn QQ Thành Công',
    copiedZALOShortUrl: 'Sao Chép URL Chống Chặn Zalo Thành Công',
    copiedLink: 'Sao Chép Thành Công',
    downloadQRCode: 'Tải Mã QR Về',
  },
  commissionInfo: {
    commissionInfo: 'Kế hoạch chiết khấu hoa hồng',
    affiliateCommissionInfo: 'Thông Tin Hoa Hồng Đại Lý',
    commissionCalculator: 'Máy Tính Hoa Hồng',
    monthlyTotalEffectiveTurnover: 'Tổng Doanh Số Thực Hiện Hàng Tháng',
    pleaseInsertMonthlyTotalEffectiveTurnover:
      'Vui lòng Nhập Tổng Doanh Số Thực Hiện Hàng Tháng',
    monthlySettlement: 'Thanh Toán Hàng Tháng',
    pleaseInsertMonthlySettlement: 'Vui lòng Nhập Thanh Toán Hàng Tháng',
    monthlyDividen: 'Thưởng Hàng Tháng',
    pleaseInsertMonthlyDividen: 'Vui lòng Nhập Thưởng Hàng Tháng',
    platformFee: 'Phí Nền Tảng',
    monthlyTotalDeposit: 'Tổng Số Tiền Gửi Hàng Tháng Của Thành Viên',
    pleaseInsertMonthlyTotalDeposit:
      'Vui lòng Nhập Tổng Số Tiền Gửi Hàng Tháng Của Thành Viên',
    monthlyTotalWithdrawal: 'Tổng Số Tiền Rút Hàng Tháng Của Thành Viên',
    pleaseInsertMonthlyTotalWithdrawal:
      'Vui lòng Nhập Tổng Số Tiền Rút Hàng Tháng Của Thành Viên',
    totalCommissionAbleClaim: 'Tổng Số Hoa Hồng Có Thể Yêu Cầu Hàng Tháng',
    calculate: 'Tính Toán',
    commissionCalculatorNote:
      'Ghi chú: Trình mô phỏng tính dựa trên ước tính hoàn trả trung bình và tiền thưởng, và lựa chọn sân vận động mặc định là tình huống mà tất cả các thành viên tham gia trò chơi tại cùng một sân vận động. Kết quả chỉ mang tính tham khảo.',
  },
  sortType: {
    ASC: 'ASC',
    DESC: 'DESC',
  },
  fields: {
    account: 'Tài khoản',
    accountInfo: 'Thông tin Tài khoản',
    activeMember: 'Thành viên Hoạt động',
    activePlayer: 'Người chơi Hoạt động',
    activeUsers: 'Người dùng Hoạt động',
    add: 'Thêm',
    addBankCard: 'Thêm Thẻ Ngân hàng',
    addVirtualCard: 'Thêm Thẻ Ảo',
    adjust: 'Điều chỉnh',
    adjustAmount: 'Số tiền Điều chỉnh',
    adjustment: 'Điều chỉnh',
    adjustReason: 'Lý do Điều chỉnh',
    adjustType: 'Loại Điều chỉnh',
    affiliate: 'Đại lý',
    affiliateAccount: 'Tên Tài khoản đại lý',
    affiliateCode: 'Mã Đại lý',
    affiliateDeposit: 'Gửi tiền',
    affiliateInfo: 'Thông tin Đại lý',
    affiliateLevel: 'Cấp Đại lý',
    affiliateStatus: 'Trạng thái Đại lý',
    affiliateWithdraw: 'Rút tiền đại lí',
    affiliateWithdrawRecord: 'Hồ sơ rút tiền ngân hàng',
    amount: 'Số lượng',
    amountOfFirstDeposit: 'Số tiền Gửi tiền Lần đầu',
    answerOne: 'Câu trả lời 1',
    answerTwo: 'Câu trả lời 2',
    answerThree: 'Câu trả lời 3',
    answerSecurityQuestion: 'Trả lời tất cả Câu hỏi Bảo mật',
    appLink: 'Liên kết Tải ứng dụng',
    balance: 'Số dư',
    bank: 'Ngân hàng',
    bankCard: 'Thẻ Ngân hàng',
    bankId: 'ID Ngân hàng',
    bankName: 'Tên Ngân hàng',
    bankCode: 'Mã Ngân hàng',
    bet: 'Cược',
    betMembers: 'Thành viên Cược',
    betRecord: 'Ghi lại Cược',
    betRecordDetails: 'Chi tiết Ghi lại Cược',
    betTime: 'Thời gian Cược',
    bind: 'Ràng buộc',
    bindAccountList: 'Danh sách Tài khoản Ràng buộc',
    bindBankCardList: 'Danh sách Thẻ Ngân hàng Ràng buộc',
    bindCryptoList: 'Danh sách Tiền mã hóa Ràng buộc',
    binded: 'Đã ràng buộc',
    bindEWalletList: 'Danh sách Ví Điện tử Ràng buộc',
    bindSecurityQn: 'Thiết lập Câu hỏi Bảo mật',
    bindWithdrawPw: 'Thiết lập Mật khẩu Rút tiền',
    bonus: 'Thưởng',
    bulk_read: 'Đọc hàng loạt',
    bulk_delete: 'Xóa hàng loạt',
    cancel: 'Hủy',
    cardAccount: 'Tài khoản Thẻ',
    cardAddress: 'Địa chỉ Thẻ',
    cardNumber: 'Số Thẻ',
    changeWithdrawPw: 'Thay đổi Mật khẩu Rút tiền',
    clearingSum: 'Tổng số rõ',
    commission: 'Hoa hồng',
    gamecommission: "Hoa hồng nền tản",
    commissionBalance: 'Số dư Hoa hồng',
    commissionPercent: 'Phần trăm Hoa hồng',
    commissionRate: 'Tỷ lệ Hoa hồng',
    commissionReport: 'Báo cáo Hoa hồng',
    commissionTransfer: 'Chuyển Hoa hồng',
    commissionWallet: 'Ví Hoa hồng',
    companyProfit: 'Lợi nhuận của Công ty',
    confirm: 'Xác nhận',
    confirmNewPassword: 'Xác nhận Mật khẩu Mới',
    confirmWithdraw: 'Xác nhận Rút tiền',
    copy: 'Sao chép',
    createAffiliate: 'Tạo Đại lý',
    createTime: 'Thời gian Tạo',
    creditFlow: 'Luồng Tín dụng',
    creditFlowAmount: 'Số tiền Luồng Tín dụng',
    creditFlowId: 'ID',
    creditFlowType: 'Loại Luồng Tín dụng',
    creditFlowDate: 'Ngày Luồng Tín dụng',
    creditFlowBalance: 'Số dư Luồng Tín dụng',
    crypto: 'Tiền Mã hóa',
    currentPassword: 'Mật khẩu Hiện tại',
    deduct: 'Khấu trừ',
    deposit: 'Gửi tiền',
    depositAmount: 'Số tiền Gửi tiền',
    depositBettingAmount: 'Số tiền Gửi tiền/ Số tiền Cược',
    depositCount: 'Số lần Gửi tiền',
    depositDate: 'Ngày Gửi tiền',
    depositRecord: 'Ghi lại Gửi tiền',
    depositUsers: 'Người dùng Gửi tiền',
    depositWallet: 'Ví Gửi tiền',
    domainAffiliate: 'Tên Miền Đại lý',
    domainApp: 'Tên Miền PC',
    domainWeb: 'Tên Miền Di Động',
    downlineAffiliate: 'Đại lý Thấp hơn',
    downlineCommission: 'Hoa hồng Thấp hơn',
    downlineCommissionRate: 'Tỷ lệ Hoa hồng Thấp hơn',
    downlineMember: 'TÊN THÀNH VIÊN',
    downlineProfit: 'Lợi nhuận Thấp hơn',
    download: 'Tải xuống',
    edit: 'Chỉnh sửa',
    editAffiliate: 'Chỉnh sửa Đại lý',
    editRealName: 'Cập nhật Tên thật',
    email: 'Địa chỉ Email',
    endDate: 'Ngày kết thúc',
    enquire: 'Hỏi',
    enterTheWithdrawalAmount: 'Nhập số tiền rút',
    estimatedAffiliateCommission: 'Ước tính Hoa hồng Đại lý',
    estimatedMemberCommission: 'Ước tính Hoa hồng Thành viên',
    ewallet: 'Ví điện tử',
    eventClickCount: 'số lần nhấp vào trang',
    finalSum: 'Tổng cuối cùng',
    finishDate: 'Ngày Kết thúc',
    firstDepositAmount: 'Số tiền Gửi tiền Lần đầu',
    firstDepositUsers: 'Người dùng Gửi tiền Lần đầu',
    ftd: 'Gửi tiền Lần đầu',
    gameName: 'Tên Trò chơi',
    gameType: 'Loại Trò chơi',
    lastLoginTime: 'Thời gian Đăng nhập cuối',
    lastMonth: 'Tháng trước',
    lastMonthTotal: 'Hoa hồng tháng trước',
    lastWeek: 'Tuần trước',
    link: 'Liên kết Mời',
    loginName: 'Tên Đăng nhập',
    loginPassword: 'Mật khẩu',
    member: 'Thành viên',
    memberBetRecords: 'Ghi lại Cược của Thành viên',
    memberCommission: 'Hoa hồng của Thành viên',
    memberProfitDownlineProfitUnsettleCommission:
      'Lợi nhuận của Thành viên / Lợi nhuận Dưới đường / Hoa hồng chưa giải quyết',
    memberInfo: 'Thông tin Thành viên',
    memberType: 'Loại Thành Viên',
    month: 'Tháng',
    monthlyAffiliateCommission: 'Hoa hồng hàng tháng của Đại lý',
    monthBeforeLastTotal: 'Hoa hồng tháng này',
    monthlyMemberCommission: 'Hoa hồng hàng tháng của Thành viên',
    newMemberCount: 'Số lượng thành viên mới',
    newMember: 'Đăng ký mới Thành viên',
    activeMemberCount: 'Số lượng thành viên hoạt động',
    totalRegisterCount: 'Tổng số lượng đăng ký',
    myAccount: 'Tài khoản của tôi',
    realName: 'Họ và tên',
    name: 'Tên',
    netProfit: 'Lợi nhuận ròng',
    newPassword: 'Mật khẩu mới',
    newUsers: 'Người dùng mới',
    ngr: 'NGR',
    noData: 'Không có dữ liệu',
    actions: 'Hành động',
    operate: 'Vận hành',
    operationalData: 'Dữ liệu Vận hành',
    password: 'Mật khẩu',
    paymentFee: 'Phí thanh toán',
    paymentMethod: 'Phương thức thanh toán',
    paymentName: 'Tên thanh toán',
    payout: 'Thanh toán',
    personal: 'Cá nhân',
    personalInfo: 'Thông tin Cá nhân',
    privilegeSerialNumber: 'mã hóa',
    privilegeRecord: 'Danh sách ưu đãi',
    privilegeName: 'Tên ưu đãi',
    platform: 'Nền tảng',
    platformFee: 'Phí Nền tảng',
    profit: 'Lợi nhuận',
    questionOne: 'Câu hỏi 1',
    questionTwo: 'Câu hỏi Hai',
    questionThree: 'Câu hỏi Ba',
    readed: 'Đã đọc',
    rebate: 'Hoàn trả',
    recordTime: 'Thời gian Ghi nhận',
    reenterPassword: 'Nhập lại Mật khẩu',
    referralCode: 'Mã Giới thiệu',
    referralLink: 'Liên kết Giới thiệu',
    regenerate: 'Tạo lại',
    registerTime: 'Thời gian Đăng ký',
    reset: 'Thiết lập lại',
    result: 'Kết quả',
    revenueShare: 'Chia sẻ Doanh thu',
    revenueShareRate: 'Tỷ lệ Chia sẻ Doanh thu',
    rollover: 'VÒNG CƯỢC',
    search: 'Tìm kiếm',
    secondLevelAffiliateCommission: 'Hoa hồng Cấp hai của Đại lý',
    securityInfo: 'Thông tin Bảo mật',
    securityQuestion: 'Câu hỏi Bảo mật',
    select: 'Chọn',
    selectACard: 'Chọn một thẻ',
    selectBank: 'Chọn một ngân hàng',
    selectBankCard: 'Chọn thẻ ngân hàng',
    selectUsdtWallet: 'Vui lòng chọn ví USDT',
    serialNumber: 'Số Seri',
    paymentType: 'Loại',
    settleTime: 'Thời gian thanh toán',
    settleView: 'Xem',
    settlePay: 'Thanh toán',
    settleEdit: 'Chỉnh sửa',
    site: 'Trang web',
    startDate: 'Ngày bắt đầu',
    status: 'Trạng thái',
    subtotal: 'Tổng phụ',
    systemAlert: 'Cảnh báo Hệ thống',
    affiliateSuccessSubmit:
      'Kính gửi đối tác, thông tin của bạn đã được gửi thành công. Đại lý của chúng tôi sẽ thông báo cho bạn về kết quả xem xét trong vòng 24 giờ. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với chuyên viên đại lý hoặc dịch vụ chăm sóc  khách hàng trực tuyến của chúng tôi. Cảm ơn bạn. ',
    systemAnnouncement: 'Thông báo Hệ thống',
    telephone: 'Số Điện thoại',
    thirdLevelAffiliateCommission: 'Hoa hồng Cấp ba của Đại lý',
    thisYear: 'Năm này',
    thisThreeMonths: 'Ba tháng này',
    thisMonth: 'Tháng này',
    thisWeek: 'Tuần này',
    title: 'Tiêu đề',
    today: 'Hôm nay',
    totalBet: 'Tổng Cược',
    totalCompanyProfit: 'Tổng Lợi nhuận Công ty',
    totalCommissionProfit: 'Tổng Lợi nhuận Hoa hồng',
    totalCommission: 'Tổng Hoa hồng',
    totalDeposit: 'Tổng Tiền Gửi',
    totalDownlineAffiliate: 'Tổng Đại lý Dưới Đường',
    totalDownlineMember: 'Tổng Thành viên Dưới Đường',
    totalPayout: 'Tổng Thanh toán',
    totalTransfer: 'Tổng Chuyển khoản',
    totalWithdraw: 'Tổng Rút tiền',
    transactionId: 'ID Giao dịch',
    transfer: 'Chuyển khoản',
    transferAmount: 'Số tiền Chuyển khoản',
    transferDate: 'Ngày Chuyển khoản',
    transferIn: 'Chuyển vào',
    transferOut: 'Chuyển ra',
    transferRecord: 'Ghi lại Chuyển khoản',
    transferType: 'Loại Chuyển khoản',
    transferUsers: 'Người dùng Chuyển khoản',
    unread: 'Chưa đọc',
    upperName: 'Tên Đại lý Trực tiếp',
    usdtWallet: 'Ví USDT',
    usdtWalletAddress: 'Địa chỉ Ví USDT',
    unsettleCommission: 'Hoa hồng Chưa giải quyết',
    updateBy: 'người cập nhật',
    viewDetails: 'Xem Chi tiết',
    vipLevel: 'Cấp độ VIP',
    visitsNo: 'Số lượt truy cập',
    winLoss: 'Thắng/Thua',
    withdraw: 'Rút tiền',
    withdrawDate: 'Ngày rút tiền',
    confirmStatus: 'Xác nhận trạng thái',
    withdrawRecordAff: 'Rút tiền đại lí',
    withdrawAmount: 'Số tiền Rút',
    withdrawCount: 'Số lần Rút',
    withdrawPassword: 'Mật khẩu Rút tiền',
    withdrawPayoutBonus: 'Rút Thưởng Bonus Đã Được Chỉnh Sửa',
    withdrawalAmount: 'Số tiền Rút',
    yesterday: 'Hôm qua',
    memberTag: 'Nhãn Thành viên',
    memberTagDescription: 'Vui lòng nhập mô tả trong vòng 10 ký tự',
    tagSetting: 'Cài đặt Nhãn',
    createTag: 'Tạo Nhãn',
    editTag: 'Chỉnh sửa Nhãn',
    memberAmount: 'Số lượng Thành viên',
    delete: 'Xóa',
    sequence: 'STT',
    back: 'Quay lại',
    all: 'Tất cả',
    yes: 'Có',
    no: 'Không',
    more: 'Thêm',
    remark: 'Ghi chú Thành viên',
    bonusRecord: 'Ghi lại Thưởng',
    ftdTime: 'Thời gian Gửi tiền Lần đầu',
    validBet: 'Cược hợp lệ',
    unAssigned: 'Chưa được Giao',
    batchEditTag: 'Chỉnh sửa Nhãn Mass',
    personalReport: 'Báo cáo Cá nhân',
    platformFeePercentage: 'Phần trăm phí',
    rolloverAmount: 'Số lượt quay',
    privilegeType: 'Loại đặc quyền',
    personalCommission: 'Hoa hồng cá nhân',
    payTime: 'Thời gian thanh toán',
    payStatus: 'Trạng thái thanh toán',
    accountAdjustment: 'Điều chỉnh tài khoản',
    commissionAdjustment: 'Điều chỉnh hoa hồng',
    image: 'Hình ảnh',
    imageTitle: 'Tiêu đề hình ảnh',
    imageType: 'Loại hình ảnh',
    uploadTime: 'Thời gian tải lên',
    imageSize: 'Kích thước hình ảnh',
    downloadTime: 'Số lần tải xuống',
    generateQR: 'Tạo mã QR',
    qrCode: 'Mã QR',
    textInfo: 'Thông tin văn bản',
    qrCodeColor: 'Màu mã QR',
    qrCodeBgColor: 'Màu nền mã QR',
    qrCodeLink: 'Liên kết mã QR',
    qrCodePreview: 'Xem trước mã QR',
    text: 'Văn bản',
    textColor: 'Màu văn bản',
    textBgColor: 'Màu nền văn bản',
    textPreview: 'Xem trước văn bản',
    preview: 'Xem trước',
    custom: 'Tùy chỉnh',
    packHistory: 'Lịch sử gói',
    packType: 'Loại gói',
    selectSystem: 'Chọn hệ thống',
    appVersion: 'Phiên bản ứng dụng',
    appName: 'Tên ứng dụng',
    appIcon: 'Biểu tượng ứng dụng',
    packNow: 'Đóng gói Ngay',
    upload: 'Tải lên',
    packDate: 'Ngày Đóng gói',
    osType: 'Loại Hệ thống',
    buildStatus: 'Trạng thái Xây dựng',
    apkType: 'Loại APK',
    normal: 'Bình thường',
    newKey: 'Chống Virus',
    detail: 'Chi tiết',
    packSize: 'Kích thước Đóng gói',
    packDownloadUrl: 'URL Tải xuống Gói Kênh',
    unchanged: 'Không thay đổi',
    unbind: 'Gỡ ràng buộc',
    depositWithdrawalProfit: 'Lợi nhuận Rút/Gửi Tiền',
    registerCount: 'Số lần Đăng ký',
    ftdCount: 'Số lần FTD',
    ftdAmount: 'Số tiền FTD',
    indBet: 'Cược',
    indProfit: 'Lợi nhuận',
    last3Months: '3 Tháng trước',
    total: 'Tổng cộng',
    betAmount: 'Số tiền Cược',
    payoutAmount: 'Số tiền Thanh toán',
    totalMemberDepositAmount: 'Tổng số tiền Gửi tiền của Thành viên',
    totalMemberDepositCount: 'Tổng số lần Gửi tiền của Thành viên',
    bonusAmount: 'Số tiền Thưởng',
    rebateAmount: 'Số tiền Hoàn lại',
    indBonusAmount: 'Số tiền Thưởng',
    indRebateAmount: 'Số tiền Hoàn lại',
    indAdjustAmount: 'Số tiền Điều chỉnh',
    grossProfit: 'Lợi nhuận gộp',
    totalBetMemberCount: 'Tổng số lượng Thành viên Cược',
    totalDepositMemberCount: 'Tổng số lượng Thành viên Gửi tiền',
    orderBy: 'Sắp xếp theo',
    sortType: 'Loại Sắp xếp',
    exclusiveDomain: 'Tên Miền Độc quyền',
    pcWay: 'PC',
    mobileWay: 'Di Động',
    forgetPassword: 'Quên Mật khẩu',
    authenticator: 'Ứng dụng Xác thực',
  },
  google: {
    google_auth_menu: 'Xác thực Google',
    download_install: 'Tải xuống Ứng dụng',
    add_key: 'Thêm Khóa Bảo mật',
    bind_verify: 'Ràng buộc Google Auth',
    popup_title: 'Xác thực Google',
    popup_msg:
      'Google Authenticator là một công cụ mật khẩu động tự động làm mới sau mỗi 30 giây. Khi thực hiện các hành động nhạy cảm như chuyển khoản tiền, rút tiền hoặc điều chỉnh cài đặt bảo mật trong hệ thống backend của người quản lý, bạn cần nhập mã xác thực 6 chữ số này.',
    popup_hint:
      'Mã xác thực danh tính phải được sử dụng kèm với tài khoản ủy quyền.',
    i_know: 'Tôi Hiểu',
    download_way: 'Cách Tải xuống：',
    download_through_way: '1. Qua Link Tải xuống：',
    ios_download: 'Tải xuống cho iOS',
    android_download: 'Tải xuống cho Android',
    ios_scan_download: 'Quét để Tải xuống iOS',
    android_scan_download: 'Quét để Tải xuống Android',
    if_cannot_download_you_can:
      '2. Nếu bạn không thể tải xuống, bạn có thể tìm kiếm trên Apple App Store."',
    or_search_in_google: '", hoặc tìm kiếm trên Google Play Store.',
    download_and_install: '" để tải xuống & cài đặt。',
    prev_step: 'Bước trước đó',
    next_step: 'Bước Tiếp Theo',
    keyin_pass: 'Nhập Mật khẩu & Tiếp theo',
    keyin_your_password: 'Nhập Mật khẩu Đăng nhập',
    tips_installed:
      'Mẹo nhanh: Sau khi tải xuống và cài đặt, chỉ cần nhấp "Tiếp theo" để tiếp tục.',
    qr_code: 'Mã QR',
    secret_key: 'Khóa Bí Mật',
    add_step: 'Các Bước để Thêm',

    add_step_desc:
      'Mở Google Authenticator, nhấn vào dấu "+" ở góc dưới bên phải, chọn "Nhập key bằng tay," và nhập bất kỳ tài khoản nào để ràng buộc với key bí mật đã đề cập ở trên.  (Quét mã QR có thể tự động thêm nó vào.)',
    check_example: 'Xem Ví dụ',
    back: 'Quay lại',
    small_tips: 'Mẹo nhanh:',
    small_tips_info_1:
      '1. Nếu bạn mất điện thoại hoặc gỡ ứng dụng xác thực, key có thể giúp bạn khôi phục máy xác thực. Vui lòng giữ nó an toàn.',
    small_tips_info_2:
      '2. Để bảo vệ tài khoản của bạn, vui lòng không đánh dấu tài khoản proxy và địa chỉ backend proxy trong quá trình ràng buộc.',
    keyin_6_digit_google: 'Vui lòng nhập mã xác thực Google 6 chữ số.',
    auth_code: 'Google Authenticator',
    bind_now: 'Ràng buộc Ngay',
    please_enter_password: 'Vui lòng nhập mật khẩu của bạn',
    added_success: 'Mã Google Authenticator được thêm thành công',
    if_not_google_auth_then:
      'Nếu bạn chưa ràng buộc Google Authenticator, không cần phải điền vào.',
    google_auth_code: 'Nhập mã Google Authenticator 6 chữ số',
  },
  forgetPassword: {
    verifyAuth: 'Xác thực',
    verifyQues: 'Câu hỏi bảo mật',
    resetPassword: 'Đặt lại mật khẩu',
    verify: 'Xác nhận',
    submit: 'Gửi',
    reset: 'Đặt lại',
    answer: 'Câu trả lời',
    messageAuth: 'Vui lòng nhập tên đăng nhập và mã xác thực Google',
    messageQues: 'Vui lòng trả lời câu hỏi bảo mật sau đây',
    messageReset: 'Vui lòng đặt lại mật khẩu của bạn',
    noSecurityQuestionSet:
      'Tài khoản này không thiết lập câu hỏi bảo mật, vui lòng liên hệ bộ phận hỗ trợ khách hàng',
    resetSuccess: 'Đặt lại mật khẩu thành công',
  },
  message: {
    adjustSuccess: 'Điều chỉnh thành công',
    addSuccess: 'Thêm thành công',
    bindBankCard: 'Vui lòng ràng buộc thẻ ngân hàng trước',
    bindUsdtWallet: 'Vui lòng ràng buộc ví USDT trước',
    chineseCharacters: 'Vui lòng nhập ký tự Trung Quốc',
    commissionPaySuccess: 'Thanh toán hoa hồng thành công',
    confirmToAdjust:
      'Bạn có chắc chắn muốn điều chỉnh? Không thể thực hiện điều chỉnh khác sau khi xác nhận.',
    confirmToPay: 'Xác nhận bạn muốn tiến hành thanh toán?',
    deleteSuccess: 'Xóa thành công',
    domainAppCopied: 'Tên miền web đã được sao chép vào bảng tạm.',
    domainWebCopied: 'Tên miền ứng dụng đã được sao chép vào bảng tạm.',
    editSuccess: 'Chỉnh sửa thành công',
    inputRealName: 'Tên thật của thành viên chưa được thiết lập',
    emailFormat: 'Vui lòng nhập đúng định dạng địa chỉ email',
    inputChineseCharacters: 'Vui lòng nhập ký tự Trung Quốc',
    inputDigits: 'Vui lòng nhập chữ số',
    inputPassword: 'Vui lòng nhập mật khẩu',
    inputPasswordAgain: 'Vui lòng nhập lại mật khẩu',
    length6To12: 'Độ dài phải từ 6 đến 12 ký tự',
    lengthShouldBe: 'Độ dài phải là',
    passwordLength:
      'Mật khẩu không được ít hơn 6 chữ số hoặc nhiều hơn 12 chữ số',
    redirectBankDeposit:
      'Bạn đã được chuyển hướng đến ngân hàng cụ thể của bạn để tiến hành nạp tiền. Khi nạp tiền thành công, nó sẽ được phản ánh ở đây.',
    reenterPassword: 'Vui lòng nhập lại mật khẩu',
    referralLinkCopied: 'Liên kết giới thiệu đã được sao chép vào bảng tạm.',
    remaining: 'Còn lại',
    requiredAffiliateLevel: 'Cấp đại lý là bắt buộc',
    required_signup_account: 'Vui lòng nhập tên tài khoản đại lý',
    lessthan50: 'Được tạo từ ít hơn 50 ký tự số hoặc chữ cái',
    required_6_to_12: 'Phải được tạo thành từ 6-12 chữ số hoặc chữ cái.',
    requried_password: 'Vui lòng nhập mật khẩu',
    required_captcha: 'Vui lòng nhập xác minh',
    required_4_digits: 'Phải được tạo thành từ 4 chữ số.',
    required_confirm_pwd: 'Vui lòng nhập xác nhận mật khẩu',
    required_same_with_password: 'Phải giống với mật khẩu đăng nhập.',
    requiredAmount: 'Số tiền là bắt buộc',
    requiredPositiveInteger: 'Chỉ cho phép số nguyên dương',
    requiredAnswer: 'Câu trả lời là bắt buộc',
    requiredCardAccount: 'Tài khoản thẻ là bắt buộc',
    requiredCardAddress: 'Địa chỉ thẻ là bắt buộc',
    requiredCardNumber: 'Vui lòng nhập số thẻ ngân hàng',
    requiredCommission: 'Hoa hồng là bắt buộc',
    requiredEmail: 'Vui lòng nhập địa chỉ email',
    requiredLoginName: 'Vui lòng nhập tên tài khoản đại lý',
    requiredMemberType: 'Vui lòng chọn loại thành viên',
    requiredOldPassword: 'Mật khẩu hiện tại là bắt buộc',
    requiredPassword: 'Vui lòng nhập mật khẩu',
    requiredRealName: 'Tên thật là bắt buộc',
    requiredRevenueShare: 'Doanh thu chia sẻ là bắt buộc',
    requiredRollover: 'Tính lại là bắt buộc',
    requiredTelephone: 'Vui lòng nhập số điện thoại',
    requiredTransferAmount: 'Số tiền chuyển khoản là bắt buộc',
    requiredUsdtWallet: 'Vui lòng nhập ví USDT',
    requiredUserName: 'Tên người dùng là bắt buộc',
    requiredWithdrawPassword: 'Mật khẩu rút tiền là bắt buộc',
    selectAQuestion: 'Vui lòng chọn một câu hỏi',
    selectBankCard: 'Vui lòng chọn một thẻ ngân hàng',
    selectUsdtWallet: 'Vui lòng chọn một ví USDT',
    setSecurityQn: 'Vui lòng thiết lập câu hỏi bảo mật',
    setSecAndPw: 'Vui lòng thiết lập câu hỏi bảo mật và mật khẩu rút tiền',
    setWithdrawPassword: 'Vui lòng thiết lập mật khẩu rút tiền',
    singleLimit: 'Giới hạn mỗi lần',
    success: 'Thành công',
    times: 'lần',
    twoPasswordNotMatch: 'Hai mật khẩu không khớp',
    unbindConfirm: 'Bạn có chắc chắn muốn gỡ bỏ ',
    validateAdjustAmountRequired: 'Số tiền điều chỉnh là bắt buộc',
    validateAdjustReasonRequired: 'Lý do điều chỉnh là bắt buộc',
    validateAdjustTypeRequired: 'Loại điều chỉnh là bắt buộc',
    validateBankCardNumber: 'Chỉ số được chấp nhận cho số thẻ ngân hàng',
    validateCommission: 'Hoa hồng phải từ 0 đến 1',
    validateNumberOnly: 'Vui lòng chỉ nhập số',
    validateNumberMoreThanOne: 'Vui lòng nhập số lớn hơn 1',
    validateRevenueShare: 'Doanh thu chia sẻ phải từ 0 đến 1',
    validateUsdtWallet: 'Chỉ chấp nhận chữ cái và số cho ví USDT',
    withdrawalToday: 'Rút tiền hôm nay',
    inputUSDTAmount: 'Vui lòng nhập số lượng USDT',
    inputAmount: 'Vui lòng nhập số tiền',
    selectAmount: 'Vui lòng chọn số tiền',
    minDepositeAmount: 'Số tiền nạp tối thiểu',
    maxDepositeAmount: 'Số tiền nạp tối đa',
    currencyRates: 'Tỷ giá tiền tệ',
    understand: 'Hiểu',
    DepositCompleted: 'Hoàn tất',
    depositNotification1:
      'Bạn sẽ được chuyển hướng đến trang của ngân hàng để hoàn tất việc nạp tiền.',
    depositNotification2:
      'Nếu thành công, bạn sẽ nhận được thông báo trên trang này.',
    validateTagDescriptionRequired: 'Mô tả thẻ là bắt buộc',
    confirmDelete:
      'Xác nhận bạn muốn xóa dữ liệu này, hành động này không thể hoàn tác',
    remarkMessage: 'Vui lòng nhập ghi chú thành viên trong vòng 200 ký tự.',
    validateTagDescriptionLength: 'Độ dài mô tả thẻ phải từ 1 đến 10',
    packRequestInQueue: 'Yêu cầu gói kênh của bạn đang trong hàng đợi',
    channelPackCancelSuccess: 'Gói kênh của bạn đã được hủy thành công',
    channelPackUrlCopied: 'Url gói kênh đã được sao chép vào bảng ghi tạm.',
    validateAppNameRequired: 'Tên ứng dụng là bắt buộc',
    validateAppIconRequired: 'Biểu tượng ứng dụng là bắt buộc',
    depositShouldBetween: 'Số tiền gửi phải nằm trong khoảng '
  },
  menu: {
    undefined: '',
    Dashboard: 'Bảng điều khiển',
    'Downline Info': 'Thông Tin Đối Tác',
    Member: 'Thành viên',
    Affiliate: 'Đại lý',
    AffiliateSummary: 'Tóm tắt đại lý',
    gameRecord: 'Hồ sơ trò chơi',
    financeCentre: 'Trung Tâm Tài Chính',
    'Bet Record': 'Hồ sơ cược của thành viên',
    'Deposit Record': 'Hồ sơ nạp tiền của thành viên',
    Statistics: 'Thống kê',
    'Game Stats': 'Thống kê trò chơi',
    'Affiliate Daily Report': 'Báo cáo hàng ngày của đại lý',
    'Affiliate Center': 'Trung tâm đại lý',
    Deposit: 'Nạp tiền',
    'Bind Bank Cards': 'Liên kết Thẻ Ngân Hàng',
    'Bank Withdrawal': 'Rút tiền từ Ngân Hàng',
    Transfer: 'Chuyển khoản',
    'Referral Centre': 'Trung Tâm Giới Thiệu',
    'Referral Management': 'Quản Lý Giới Thiệu',
    'Referral Link': 'Liên Kết Giới Thiệu',
    'Referral Material': 'Tài Liệu Giới Thiệu',
    'Affiliate Domain': 'Tên Miền Đại Lý',
    'Personal Center': 'Trung Tâm Cá Nhân',
    'Settlement Center': 'Trung Tâm Thanh Toán',
    'Finance Report': 'Báo Cáo Tài Chính',
    'Settlement Report': 'Báo Cáo Thanh Toán',
    'Channel Pack': 'Gói Kênh',
    contactUs: 'Liên hệ chúng tôi',
    'Daily Detail': 'Chi Tiết Hàng Ngày',
    'Daily Summary': 'Tóm Tắt Hàng Ngày',
    Withdraw: 'Rút tiền ngân hàng',
  },
  posterType: {
    OVERALL: 'Giới Thiệu Tổng Quan',
    APP: 'Giới Thiệu Ứng Dụng',
    SPONSOR: 'Giới Thiệu Tài Trợ',
    GIFT: 'Giới Thiệu Quà Tặng',
    COMPETITION: 'Giới Thiệu Cuộc Thi',
    EVENT: 'Giới Thiệu Sự Kiện',
    CRYPTO: 'Giới Thiệu Tiền Điện Tử',
    AFFILIATE: 'Lợi Ích Đại Lý',
  },
  packStatus: {
    IN_QUEUE: 'Trong Hàng Đợi',
    IN_PROGRESS: 'Đang Tiến Hành',
    SUCCESS: 'Thành Công',
    FAIL: 'Thất Bại',
    CANCEL: 'Hủy',
  },
  appType: {
    ALL_SITE: 'TẤT CẢ TRANG',
    SPORT: 'THỂ THAO',
    ESPORT: 'ESPORT',
  },
  member: {
    status: {
      normal: 'BÌNH THƯỜNG',
      frozen: 'ĐÓNG BĂNG',
    },
  },
  affiliate: {
    level: {
      AFFILIATE: 'Đại Lý',
      SUPER_AFFILIATE: 'Đại Lý Siêu',
      MASTER_AFFILIATE: 'Đại Lý Chính',
      CHIEF_AFFILIATE: 'Đại Lý Chủ',
    },
    status: {
      APPLY: 'Đang Chờ',
      NORMAL: 'Bình Thường',
      DISABLE: 'Vô Hiệu Hóa',
    },
  },
  osType: {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
  },

  error: {
    400: 'Yêu cầu không hợp lệ',
    403: 'Cấm truy cập',
    405: 'Phương thức không được phép',
    500: 'Lỗi hệ thống',
    501: 'ID Yêu Cầu Trùng Lặp',
    502: 'Máy Chủ Không Tìm Thấy',
    504: 'Yêu Cầu Quá Thường Xuyên',
    601: 'Lỗi Xác Minh Token',
    602: 'Token đã hết hạn',
    603: 'Token bị thiếu',
    604: 'Tài khoản đã đăng nhập',
    707: 'Số dư không đủ',
    709: 'Số dư hoa hồng không đủ',
    800: 'Lỗi Xác Minh Captcha',
    801: 'Mã Captcha đã hết hạn',
    901: 'Bản ghi liên kết đại lý không tồn tại',
    902: 'Thành viên không phải là đại lý',
    903: 'Tài khoản là một đại lý',
    904: 'Tài khoản này là thành viên',
    905: 'Tên thật chưa được thiết lập',
    1000: 'Tên đăng nhập hoặc mật khẩu không đúng',
    1001: 'Tài khoản đăng nhập đã bị vô hiệu hóa',
    1100: 'Mật khẩu mới không thể giống như mật khẩu cũ của bạn',
    1101: 'Mật khẩu cũ không đúng',
    1102: 'Mật khẩu rút tiền không chính xác',
    1201: 'Câu trả lời không chính xác',
    1308: 'Số dư không đủ',
    1311: 'Không nên gửi cùng một số tiền rút trong vòng 24 giờ',
    13000: 'Tên miền không tồn tại',
    14000: 'Tên đăng nhập trùng lặp: ',
    14001: 'Số điện thoại trùng lặp: ',
    14002: 'Email trùng lặp: ',
    14003: 'Mã liên kết đại lý không tồn tại: ',
    14004: 'Mã liên kết đại lý không thuộc về trang web này: ',
    14005: 'Hoa hồng đại lý phải ít hơn hoa hồng của đại lý cấp trên: ',
    14006: 'Doanh thu đại lý phải ít hơn doanh thu của đại lý cấp trên: ',
    14007: 'Đơn đăng ký đại lý chưa được phê duyệt',
    14008: 'Hoa hồng đại lý phải lớn hơn hoa hồng của đại lý con: ',
    14009: 'Doanh thu đại lý phải lớn hơn doanh thu của đại lý con: ',
    14100: 'Thành viên này không phải là thành viên phụ của bạn',
    14102: 'Thẻ thành viên đã tồn tại',
    14103: 'Vượt quá giới hạn thẻ thành viên',
    15201: 'Mật khẩu cũ không đúng',
    15202: 'Mật khẩu mới không thể giống như mật khẩu cũ của bạn',
    14106: 'Mật khẩu không chính xác',
    24000: 'Xác minh không thành công',
    1009: 'Mã xác minh Google không chính xác',
    900: 'Tài khoản không tồn tại',
  },
}
